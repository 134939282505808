export default {
	common: {
		noMore: '더 이상 없습니다',
		noEmpty: '반드시 업로드',
		copySuccess: '복사 성공',
		copyFail: '복사 실패',
		search: '검색',
		remark: '비고',
		commit: '제출',
		status: '상태',
		revoke: '취소',
		cancel: '취소',
		confirm: '확인',
		getbtn: '수령',
		selectTime: '시간 선택',
		selectDate: '날짜 선택',
		uploadImage: '사진 업로드',
		uploadVideo: '동영상 업로드',
		activity: '이벤트',
		problem: '문제',
		money: '금액',
		reply: '답변',
		other: '기타',
		send: '발송',
		copy: '복사',
		view: '보기',
		next: '다음',
		feedback: '피드백 할래',
		enter: '입력하세요',
		loading: '로딩 중...',
		searchkey: '검색어를 입력해 주세요',
		back: '돌아가기',
		download: '다운로드',
		detail: '상세',
		mUnit: '₩',
		thousand: '천',
		tenThousand: '만',
		tenMillion: '천만',
		billion: '억',
		hundredBillion: '천억',
		trillions: '만억',
		quadrillions: '천만억',
	},
	upload: {
		label: '업로드',
		loading: '업로드 중...',
		success: '업로드 성공',
		fail: '업로드 실패',
		sizeTips: '파일 크기는 {size} M 초과할 수 없습니다',
	},
	menu: {
		home: '메인',
		news: '운영정책',
		personal: '개인정보',
		coupon: '10만 할인 쿠폰',
		pay: '결제',
		clauseUser: '프라이버시 정책',
		clauseService: '고객 서비스 센터',
		about: '우리에 대해- 회사소개',
		contact: '저희한테 연락하세요 - 고객지원',
		refund: '환불정책',
		pcdownload: 'PC 설치 설명',
		download: '설치 설명'
	},
	login: {
		name: '계정',
		pass: '비밀번호',
		nameTips: '계정을 입력하세요',
		passTips: '비밀번호를 입력하세요',
		submitBtn: '확인',
		accountTitle: '로그인',
		thirdTitle: '타사 로그인',
		googleLogin: 'google로그인'
	},
	home: {
		tips:'GM 운영진 카톡 아이디：GM20238',
		slogans:'모든 게임 50% 할인되는 정보 app',
		news: '운영정책',
		recommend: '최신 혜택 정보',
		recommendGame: '추천 게임',
		recommendDesc: '공식 추천 게임',
		hotGame: '핫 게임',
		hotDesc: '가장 핫한 게임',
		gameDownload: '다운로드',
		pcDownload: 'PC 다운로드',
		downloadUrl: '다운로드 링크',
		activity: '이벤트',
		introduction: '공략',
		offlineActivity: '오프라인 이벤트',
		openService: '서버 오픈 공지',
		releaseTime: '출시 시간',
		gameNotice: '게임 공지',
		prebookGame: '신규 게임 예약',
		prebookDesc: '최신 공개, 신규게임 첫 출시',
		guidesTitle: '추가 가이드',
		btnText: '10만원 할인쿠폰 활성화 성공',
		guidesText:'순서대로 진행 시 10만원 할인쿠폰 획득 가능',
		newstotal: '현재 공략 {num} 개 있습니다',
		gamePlay: '게임 바로 시작',
		couponRules: '사용 설명',
		serviceTime: '시간',
		serviceGame: '게임',
		serviceList: '목록'
	},
	pay: {
		title: '결제 센터',
		orderMoney: '주문 금액',
		allCoupon: '전액 할인쿠폰',
		halfCoupon: '반값 할인쿠폰',
		total: '합계',
		discount: '할인',
		paybtn: '결제',
		authConfirm: '실명인증 가기',
		authCancel: '본인인증 완료',
		payJumpTitle: '결제 알림',
		payJumpText: '결제하시겠습니까？',
		payRecharge: '즉시 충전',
		recharge: '충전',
		rechargeMoney: '충전 금액',
		rechargeTitle: '충전 금액 선택',
		rechargePlaceholder: '충전 금액 입력하세요',
		rechargeDiscount: '첫 충전 {num}배',
		tipsInputRecharge: 'error .',
		goodMoney: '상품 가격',
		discountCoupon: '할인쿠폰',
		gmCoin: 'GM 코인',
		payType: '결제수단',
		discountMoney: '할인',
		maxDiscountTips: '스페셜 할인 적용 완료',
		discountTitle: '할인쿠폰을 선택하세요',
		noUseDiscount: '할인쿠폰 사용하지 않음',
		expTime: '유효 기한',
		percentDiscount: '100% 할인 쿠폰',
		halfPercentDiscount: '50% 할인 쿠폰',
		onlyForthisGame: '본게임에서만 사용 가능',
		fullReduction: '{num} 원 이상 결제 시 사용',
		gmCoinTitle: 'GM 코인',
		payTypeTitle: '결제 수단 선택',
		gmCoinTips: 'GM 코인은 GM 시리즈 패키지 교환에 사용 가능',
		allGMCoin: '보유 GM 코인',
		useGMCoin: 'GM 코인 소모',
		remainGMCoin: '남은 GM 코인',
		selectPayTitle: '결제 수단 선택',
		gmCoinPlaceholder: '소모할 GM 코인을 100단위로 입력해주십시오.',
		freeGetGM: '무료 획득',
		needPayTips: '유저님이 입력하신 GM코인과 할인쿠폰을 중첩 사용한 후, 결제해야할 금액이 100원 미만되어 다시 입력하시나 선택해주세요~',
		detail: '상세',
		payTypeTips: '할인쿠폰과 GM코인을 동시에 사용할 수 없습니다',
		payMaxLimit: '최고 {num}할인',
		payLimitTips: '남은 금액 없어 GM코인으로 할인할 수 없습니다~'
	},
	personal: {
		platId: '플랫폼 ID',
		platCoin: 'GM 코인',
		myPlatCoin: '현재 GM 코인',
		loginBtn: '로그인',
		loginOut: '로그아웃',
		googleEmail: 'Google 메일',
		tipsLogin: '우선 로그인 해주십시오',
		commonUser:'일반 사용자',
		vipDetail:'특권 보기'
	},
	footer: {
		aboutus: '회사 소개',
		contact: '고객센터 연락처',
		service: '개인정보취급방침',
		refund: '환불정책',
		userService: '서비스이용약관',
		telLabel: '전화번호',
		telValue: '01088727088',
		licenseNumLabel: '사업자등록번호',
		licenseNumValue: '387-81-02165',
		companyLabel: '상호명',
		companyValue: '귀위안 주식회사',
		legalPersonLabel: '대표자명',
		legalPersonValue: 'YU CHAOSHUYI',
		emailLabel: '이메일',
		emailValue: "supergmgame{'@'}gmail.com",
		bankLabel: '은행계좌',
		bankValue: 'KEB HANA BANK 189-910052-14504',
		businessNumLabel: '통신판매업신고',
		businessNumValue: '제2023-서울강남-03332호',
		addressLabel: '사업장주소',
		addressValue: '서울특별시 강남구 영동대로 602,6층 에이치41(삼성동,미켈라 107)',
		support: '© 2023 Powered by GM',
		useManual: '이용안내'
	},
	iosdown: {
		ranking: '랭킹',
		rankNum: '3위',
		age: '연령',
		ageUnit: '세',
		ageLevel: '연령 등급',
		other: '기타',
		scoreNum: '19만 개 평점',
		scoreFull: '만점 5점',
		quesTips: '다음 문제 발생 시 이곳을 클릭하여 해결할 수 있습니다',
		appQuit: '앱 종료됨',
		unInstall: '설치할 수 없음',
		blackIcon: '아이콘이 검게 변함',
		developMode: '개발자 모드를 활성화해야 함',
		comments: '평점 및 리뷰',
		suggest: '신고 및 제안',
		newFunc: '새로운 기능',
		version: '버전 1.0.2',
		info: '정보',
		price: '가격',
		free: '무료',
		size: '크기',
		type: '카테고리',
		language: '언어',
		korea: '한국어',
		privacy: '개인정보 보호 정책',
		compatible: '호환성',
		compatibleDes: 'iOS 13.0 이상 필요. iPhone, iPad 및 iPod touch와 호환됨.',
		disclaimer: '면책 조항',
		disclaimerDes: '본 웹사이트는 개발자에게 앱 다운로드 및 설치 내부 테스트를 위한 호스팅을 제공할 뿐이며, 앱 내 콘텐츠 및 운영 관련 사항은 앱 개발자가 책임지며 본 웹사이트와 무관합니다.',
		btn1: '애플 인증 중, 팝업에서 허용을 클릭하세요',
		btn2: '팝업 창에서 [열기] 버튼을 클릭하여 설치하세요',
		btn3: '해당 UDID 활성화가 지연될 수 있음',
		developTitle: '【개발자 모드】 활성화 튜토리얼',
		developNext: '다음 단계로 이동',
		developEnd: '튜토리얼 종료, 첫 번째 단계로 돌아가기',
		developOpen: '활성화 완료, 다시 표시하지 않기',
		SafariTips: '모바일 기기에서 iOS Safari로 열어주세요',
		findAppError: '앱을 찾을 수 없습니다!',
		networkError: '네트워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요!',
		ParamsError: '사용자 지정 매개변수 오류입니다. 입력값을 확인해주세요!',
		signStatus: '현재 서명 상태',
		signStep: '현재 서명 진행 상황',
		signFail: '서명 실패',
		failTips: '사용자에게 임시 버전을 다운로드하거나 클라우드 게임 버전을 이용하도록 안내하세요.',
		down1: '임시 버전 다운로드 링크',
		down2: '클라우드 게임 버전 열기 링크',
		fixStatus: '수정 상태',
		fixBtn: '수정 시작',
		developBtn: '개발자 모드 활성화 가이드',
		fixCon1: '친애하는 사용자 여러분, 앱에서 다음과 같은 문제가 발생하면:',
		fixCon2: '• 앱 아이콘이 검게 변하며 열리지 않는 경우',
		fixCon3: '• 앱을 설치할 수 없거나 실행 후 강제 종료되는 경우',
		fixCon4: '앱 복구 도우미를 사용하여 {str}을 클릭하고 안내에 따라 UDID를 입력하면 복구가 완료됩니다.',
		fixCon4Str: '‘수정 시작’ 버튼',
		fixCon5: '복구 완료 후 1분 뒤, 정상적으로 작동하지 않는 앱을 삭제하고 다시 다운로드하면 정상적으로 사용할 수 있습니다. 여전히 문제가 지속되면 {str}에 문의해주세요.',
		fixCon5Str: '고객센터',
		developCon1: '앱을 실행할 때 다음과 같은 팝업이 나타날 경우:',
		developCon2: '• 「개발자 모드를 활성화해야 합니다」',
		developCon3: '{str}를 클릭하고 안내에 따라 활성화하세요. 한 번만 설정하면 이후에는 어떤 앱이든 추가 설정 없이 다운로드할 수 있습니다.',
		developCon3Str: '아래의 [개발자 모드 활성화 가이드]',
		developCon4: '（개발자 모드를 활성화해도 기기의 보안에는 영향을 미치지 않습니다）',
		notIOS: '애플 애플리케이션은 안드로이드 기기를 지원하지 않습니다!'
	}
}