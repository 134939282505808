import { createRouter, createWebHistory } from 'vue-router';
import useConfigStore from '@/store/modules/config'
import i18n from '@/lang/index.js'
import Layout from '@/layout'

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'Home',
    },
  },
  {
    path: '',
    component: Layout,
    redirect: '/home',
    children: [
		{
		  path: '/home',
		  component: () => import('@/view/home/index.vue'),
		  redirect: '/home/recommend',
		  name: 'Home',
		  meta: { title: 'menu.home', activeMenu: 'home', noCache: true },
		  children: [
				{
				  path: 'recommend/:platid?',
				  name: 'Recommend',
				  meta: { title: 'menu.home', noCache: true },
				  component: () => import('@/view/home/recommend.vue'),
				},
				{
				  path: 'news/:platid?',
				  name: 'News',
				  meta: { title: 'menu.home', noCache: true },
				  component: () => import('@/view/home/news.vue')
				},
				{
				  path: 'search/:platid?',
				  name: 'Search',
				  meta: { title: 'menu.home', activeMenu: 'search', noCache: true },
				  component: () => import('@/view/home/search.vue')
				}
			]
		},
		{
			  path: '/game/:id/:platid?',
			  component: () => import('@/view/game/index.vue'),
			  name: 'Game',
			  meta: { title: 'menu.home', activeMenu: 'game', noCache: true }
		},
		{
			  path: '/gameweb/:id/:platid?',
			  component: () => import('@/view/game/web.vue'),
			  name: 'GameWeb',
			  meta: { title: 'menu.home', activeMenu: 'game', noCache: true }
		},
		{
			  path: '/gameplay/:id/:platid?',
			  component: () => import('@/view/game/play.vue'),
			  name: 'GamePlay',
			  meta: { title: 'menu.home', activeMenu: 'game', noCache: true }
		},
		{
			  path: '/introduct/:id/:platid?',
			  component: () => import('@/view/game/introduct.vue'),
			  name: 'GameIntroduct',
			  meta: { title: 'menu.home', activeMenu: 'game', noCache: true }
		},
		{
			  path: '/news/:id/:platid?',
			  component: () => import('@/view/home/newsdetail.vue'),
			  name: 'Newsdetail',
			  meta: { title: 'menu.home', activeMenu: 'news', noCache: true }
		},
		{
			path: '/coupon/:platid?',
			component: () => import('@/view/coupon/index.vue'),
			name: 'Coupon',
			meta: { title: 'menu.coupon', activeMenu: 'coupon', noCache: true }
		},
		{
			path: '/pcdownload/:platid?',
			component: () => import('@/view/pcdownload/index.vue'),
			name: 'Pcdownload',
			meta: { title: 'menu.pcdownload', activeMenu: 'pcdownload', noCache: true }
		},
		{
			path: '/personal/:platid?',
			component: () => import('@/view/personal/index.vue'),
			name: 'Personal',
			meta: { title: 'menu.personal', activeMenu: 'personal', noCache: true }
		},
		{
			path: '/infos/:platid?',
			component: () => import('@/view/personal/infos.vue'),
			name: 'Infos',
			meta: { title: 'footer.useManual', activeMenu: 'personal', noCache: true }
		},
		{
			path: '/vipInfo/:platid?',
			component: () => import('@/view/personal/vipInfo.vue'),
			name: 'VipInfo',
			meta: { title: 'footer.useManual', activeMenu: 'personal', noCache: true }
		},
		{
			  path: '/recharge/:platid?',
			  component: () => import('@/view/pay/recharge.vue'),
			  name: 'Recharge',
			  meta: { title: 'menu.pay' },
		},
		{
			  path: '/payment/:platid?',
			  component: () => import('@/view/pay/payment.vue'),
			  name: 'Payment',
			  meta: { title: 'menu.pay', activeMenu: 'payment', noCache: true },
		},
		{
			  path: '/payments/:platid?',//新版本（自己收银台）
			  component: () => import('@/view/pay/payments.vue'),
			  name: 'Payments',
			  meta: { title: 'menu.pay', activeMenu: 'payments', noCache: true },
		},
		{
			  path: '/paymentTest/:platid?',
			  component: () => import('@/view/pay/paymentTest.vue'),
			  name: 'PaymentTest',
			  meta: { title: 'menu.pay', activeMenu: 'paymentTest', noCache: true },
		},
		{
			  path: '/pages/about/:platid?',
			  component: () => import('@/view/pages/about.vue'),
			  name: 'About',
			  meta: { title: 'menu.about' },
		},
		{
			  path: '/pages/contact/:platid?',
			  component: () => import('@/view/pages/contact.vue'),
			  name: 'Contact',
			  meta: { title: 'menu.contact' },
		},
		{
			  path: '/pages/refund/:platid?',
			  component: () => import('@/view/pages/refund.vue'),
			  name: 'Refund',
			  meta: { title: 'menu.refund' },
		}
    ]
  },
  {
  	  path: '/clauseService/:platid?',
  	  component: () => import('@/view/clause/service.vue'),
  	  name: 'ClauseService',
  	  meta: { title: 'menu.clauseService' },
  },
  {
  	  path: '/clauseUser/:platid?',
  	  component: () => import('@/view/clause/user.vue'),
  	  name: 'ClauseUser',
  	  meta: { title: 'menu.clauseUser' },
  },
  {
	  path: '/payresult/:id/:source?',
	  component: () => import('@/view/pay/index.vue'),
	  name: 'Payresult',
	  meta: { title: 'menu.pay' },
  },
  {
  	  path: '/download',
  	  component: () => import('@/view/download/index.vue'),
  	  name: 'Download',
  	  meta: { title: 'menu.download' },
  }
];

const router = createRouter({
  mode:'history',
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
	if(from.params.platid && to.params.platid == '' && to.fullPath.indexOf(from.params.platid) == -1){
		to.params.platid = from.params.platid
		to.fullPath += '/'+from.params.platid
	}
	const title = to.meta && to.meta.title;
	if (title) {
		document.title = i18n.global.t(title);
	}
	useConfigStore().setIsLuoli(false)
	next();
});

export { router };